import { defineComponent } from '@vue/composition-api';
import { ref } from 'vue';
import { CHAT_GROUP } from '../../http/api';
import { Toast } from 'vant';
export default defineComponent({
  setup() {
    const qrcode = ref("");
    const city_name = ref("");
    CHAT_GROUP({}).then(res => {
      qrcode.value = res.data.data.qrcode;
      city_name.value = res.data.data.city_name;
      console.log(qrcode);
      console.log(city_name);
      Toast.clear();
    });
    return {
      qrcode,
      city_name
    };
  }

});